body {
  background-color: #212121;
  
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
}

.App {
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  height:100vh;
}


/* Modifiers for onscreen keyboard */

#KeyboardWrapper {
  flex: 0;
  margin-top: auto;
  width: 50%;
  bottom:50px;
}

.keyboard-wrapper .ui-keyboard-button {
  margin: 5px;
  padding:20px;
  border-radius: 0px;
  border:solid 1px #282c34;
}

@media screen and (max-width: 768px) {
  #KeyboardWrapper {
    width: 100%;
    bottom:0px;
  }
}